import React, { useEffect, useState } from "react"
import Layout from "../layout/GeneralLayout"
import { Box } from "../components/commonElements"
import CardSimu from "../components/cardSimu"
import ContentBoard from "../components/contentBoard"
import Footer from "../components/footer"
import styled from "styled-components"
import SEO from "../components/seo"
import ShareModal from "../components/modal/ShareModal"
import FileModal from "../components/modal/FileModal"
import { useSelector, useDispatch } from "react-redux"
import ToolSettings from "../components/toolSettings"
import EntriesSettings from "../components/entriesSettings"
import Adthrive from "../components/adthrive"
import LogoProfile from "../images/tools/number-image-big.png"
import ResultModal from "../components/cardSimu/resultModal"
import SettingsBoard from "../components/settingsBoard"
import { useToastContext } from "../components/contextHook/toast"
import { usePageLoadingContext } from "../components/contextHook/pageLoading"
import queryString from "query-string"
import { getPersistor } from "../utils/reduxPersistor"
import { getShareLink } from "../redux/shareLink/actions"

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.theme.containerSize};
  display: grid;
  grid-template-columns: minmax(45rem, 100%) 30rem;
  /* ${props =>
    props.hideSettings
      ? `grid-template-columns: minmax(45rem, 100%) 5rem 30rem;`
      : `grid-template-columns: minmax(45rem, 100%) 34rem 30rem;`} */
  grid-template-rows: minmax(calc(100vh - 12rem), auto);
  grid-auto-rows: auto;
  grid-template-areas: "Tool RightSide"; //"Tool LeftSide RightSide";
  gap: 1rem 1rem;
  align-items: stretch;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 1rem;
  @media (min-width: 2000px) {
    grid-template-rows: minmax(87rem, auto);
  }
  @media ${props => props.theme.device.tablet} {
    grid-template-columns: minmax(45rem, 100%) 30rem;
    grid-template-rows: minmax(53rem, auto);
    grid-auto-rows: auto;
    grid-template-areas:
      "Tool RightSide"
      "MiddleAd RightSide"
      "LeftSide RightSide";
  }
  @media ${props => props.theme.device.mobile} {
    grid-template-columns: 100%;
    grid-template-rows: minmax(50rem, auto);
    grid-auto-rows: auto;
    grid-template-areas:
      "Tool"
      "MiddleAd"
      "LeftSide"
      "RightSide";
    padding-bottom: 0rem;
  }
`

const RightSiderbar = styled(Box)`
  grid-area: RightSide;
  user-select: none;
  @media ${props => props.theme.device.mobile} {
    display: none;
  }
`

// const ToolFooterBox = styled.div`
//   position: absolute;
//   right: 0.6rem;
//   bottom: 1rem;
//   display: flex;
//   flex-direction: column;
//   gap: 1.5rem;
//   align-items: center;
// `

// const ToolExpandCollapseBox = styled.div`
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

// const SettingsShowBox = styled(GlassBox)`
//   ${props => !props.hideSettings && `display: none;`}
//   height: 100%;
//   cursor: pointer;
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

const LeftSidebar = styled(Box)`
  /* ${props => props.hideSettings && `display: none;`} */
  display: none;
  grid-area: LeftSide;
  grid-gap: 1rem 1rem;
  @media ${props => props.theme.device.tablet} {
    display: flex;
  }
`

const LeftSideSettingsBox = styled(Box)`
  grid-area: LeftSideSettings;
  grid-gap: 1rem;
  margin: 0rem;
`

const ToolBox = styled(Box)`
  -webkit-tap-highlight-color: transparent;
  grid-area: Tool;
  z-index: 1;
  user-select: none;
  margin-bottom: 0rem;
`

const MiddleAdBox = styled(Box)`
  grid-area: MiddleAd;
  display: none;
  user-select: none;
  @media ${props => props.theme.device.tablet} {
    display: flex;
  }
`

const ContentBox = styled(Box)`
  font-size: 1.8rem;
  grid-area: Content;
  background-color: white;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: ${props =>
    `minmax(45rem,${
      props.theme.containerSize.replace("rem", "") - 31
    }rem) 31rem`};
  justify-content: center;
  @media ${props => props.theme.device.mobile} {
    grid-template-columns: auto;
  }
  padding-bottom: 7rem;
  padding-top: 3rem;
`

// const LeftSidebarAd = styled(Adthrive)`
//   user-select: none;
//   & > div {
//     margin: 0;
//   }
//   @media ${props => props.theme.device.tablet} {
//     display: none;
//   }
// `

const RightSidebarAd = styled(Adthrive)`
  justify-content: flex-start;
  & > div {
    margin: 0;
  }
  @media ${props => props.theme.device.mobile} {
    display: none;
  }
`

const MobileMiddleAd = styled(Adthrive)`
  @media ${props => props.theme.device.tablet} {
    min-height: 28rem;
    & > div {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`

// const ResultIconButton = styled(IconButton)`
//   width: 3.2rem;
//   height: 3.2rem;
//   justify-content: center;
//   align-items: center;
//   border-radius: 0.2rem;
//   &:hover {
//     background-color: #f8b400;
//   }
// `

const NumberCardsPage = props => {
  const shareModalOpened = useSelector(state => state.modal.shareModalOpened)
  const fileModalData = useSelector(state => state.modal.fileModalData)
  // const [hideSettings, setHideSettings] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [menuState, setMenuState] = useState(0)
  const { setToast } = useToastContext()
  const { setPageLoading } = usePageLoadingContext()
  const dispatch = useDispatch()

  useEffect(() => {
    const search = props.location.search
      ? queryString.parse(props.location.search)
      : {}
    const shareId = search.id
    if (shareId) {
      getPersistor().pause()
      const data = {
        sharePage: "number",
        sharePath: shareId,
      }
      setPageLoading(true)
      dispatch(getShareLink(data)).then(result => {
        setPageLoading(false)
        if (result.error) {
          setToast(result.error)
        }
      })
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      document.getElementById("ToolRightSidebarAd").style.maxHeight = `calc(${
        document.getElementById("ToolContainer").offsetHeight +
        document.getElementById("ToolContentBox").offsetHeight
      }px - 1rem)`
    }
    window.addEventListener("resize", handleResize)
    handleResize()
  }, [])

  const onTitleClicked = index => {
    if (index !== menuState) {
      setMenuState(index)
    }
  }

  return (
    <>
      <Layout>
        <Container
          id="ToolContainer"
          // hideSettings={hideSettings}
        >
          {shareModalOpened && <ShareModal />}
          {fileModalData && <FileModal />}
          <LeftSidebar
            gap="1rem"
            // hideSettings={hideSettings}
          >
            <LeftSideSettingsBox>
              {menuState === 0 && (
                <EntriesSettings
                  cardType="number"
                  initialState={true}
                  title={[
                    { title: "Entries", selected: true },
                    { title: "Customizations", selected: false },
                  ]}
                  onTitleClicked={index => onTitleClicked(index)}
                />
              )}
              {menuState === 1 && (
                <ToolSettings
                  page="card"
                  type="number"
                  initialState={true}
                  title={[
                    { title: "Entries", selected: false },
                    { title: "Customizations", selected: true },
                  ]}
                  onTitleClicked={index => onTitleClicked(index)}
                />
              )}
            </LeftSideSettingsBox>
            {/* <LeftSidebarAd
              id="LeftSidebarAd"
              width="100%"
              alignItems="stretch"
              justifyContent="center"
              flex="1"
            /> */}
          </LeftSidebar>
          {/* <SettingsShowBox
            gap="1rem"
            padding="2rem 1rem"
            alignItems="center"
            justifyContent="space-between"
            hideSettings={hideSettings}
            onClick={() => {
              setHideSettings(false)
            }}
          >
            <Box gap="3rem">
              <Tooltip content="Customization">
                <Settings size={22} />
              </Tooltip>
            </Box>
          </SettingsShowBox> */}
          <ToolBox gap="1rem" positionBox="relative">
            <SettingsBoard page="card" type="number" />
            <ResultModal
              cardType="number"
              showResult={showResult}
              setShowResult={setShowResult}
            />
            <CardSimu cardType="number" />
            {/* <ToolFooterBox>
              {!showResult && (
                <Tooltip content="Result">
                  <ResultIconButton
                    aria-label="Result"
                    onClick={() => {
                      setShowResult(true)
                    }}
                    icon={<RankingStar size={24} />}
                  />
                </Tooltip>
              )}
              <ToolExpandCollapseBox>
                {hideSettings && (
                  <Tooltip content="Collapse">
                    <IconButton
                      aria-label="Collapse"
                      onClick={() => {
                        setHideSettings(false)
                      }}
                      icon={<AngleDoubleLeft size={24} />}
                    />
                  </Tooltip>
                )}
                {!hideSettings && (
                  <Tooltip content="Expand">
                    <IconButton
                      aria-label="Expand"
                      onClick={() => {
                        setHideSettings(true)
                      }}
                      icon={<AngleDoubleRight size={24} />}
                    />
                  </Tooltip>
                )}
              </ToolExpandCollapseBox>
            </ToolFooterBox> */}
          </ToolBox>
          <MiddleAdBox>
            <MobileMiddleAd
              id="MobileMiddleAd"
              width="100%"
              alignItems="stretch"
              justifyContent="center"
            />
          </MiddleAdBox>
          <RightSiderbar gap="1rem">
            <Box
              id="ToolRightSidebarAd"
              alignItems="center"
              maxWidth="30rem"
              width="100%"
              height="100%"
              positionBox="absolute"
              zIndex="99"
            >
              <RightSidebarAd
                id="RightSidebarAd"
                width="100%"
                height="100%"
                alignItems="stretch"
                justifyContent="center"
              />
            </Box>
          </RightSiderbar>
        </Container>
      </Layout>
      <ContentBox id="ToolContentBox" gap="1rem">
        <ContentBoard file="NumberCardsContent" />
      </ContentBox>
      <Footer />
    </>
  )
}

export default NumberCardsPage

export const Head = () => (
  <SEO
    pathname="/number-cards/"
    toolPage={true}
    titleP="Number Cards - Flip Number Flash Cards Virtually Online"
    descriptionP="Free online number cards or flashcards. Flip a random number for your games and teaching. Many customizations are available. Clean and interactive interface."
    imageP={LogoProfile}
  />
)
